import React from 'react';
import '../AppVanillaHtml.css';


const Courses = () => (

        <div>
            <h3><a href="https://sun-ris3.github.io/" target="_blank">Security for ML</a></h3>
        </div>
      
);


export default Courses;
